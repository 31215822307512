import React, { useReducer, useContext, createContext, useEffect } from 'react';
import { reducer, cartItemsTotalPrice } from './cart.reducer';
import { useStorage } from 'helper/use-storage';
import Log from 'helper/sentry';
import { setMixpanelUserProperty } from 'helper/gtm/helper';
const CartContext = createContext({} as any);
const INITIAL_STATE = {
  isOpen: false,
  items: [],
  coupon: null,
  postcode: null,
};

const useCartActions = (initialCart = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(reducer, initialCart);

  useEffect(() => {
    const minimumBasketOrder = state.coupon?.promotion?.minBasketOrder || 0;
    const newPrice = getCartItemsPrice() || 0;
    const promotionIneligible = newPrice < minimumBasketOrder;
    const items = state?.items || [];
    const itemsCount = items.length > 0 ? items.reduce((result, current) => result + current.quantity, 0) : 0;
    if (promotionIneligible) removeCouponHandler();

    Log.debug('Items changed', 'basket', { minimumBasketOrder, newPrice, promotionIneligible, itemsCount });

    setMixpanelUserProperty({
      'current_basket_items': itemsCount,
      'current_basket_value': newPrice?.toFixed(2) || 0,
      'current_promotion': state?.coupon?.promotion?.code || '',
    });
  }, [state.items]);

  const addItemHandler = (item, quantity = 1) => {
    dispatch({ type: 'ADD_ITEM', payload: { ...item, quantity } });
  };

  const removeItemHandler = (item, quantity = 1) => {
    dispatch({ type: 'REMOVE_ITEM', payload: { ...item, quantity } });
  };

  const clearItemFromCartHandler = (item) => {
    dispatch({ type: 'CLEAR_ITEM_FROM_CART', payload: item });
  };
  
  const clearCartHandler = () => {
    dispatch({ type: 'CLEAR_CART' });
    setPostcode(null);
    removeCouponHandler();
  };
  const toggleCartHandler = () => {
    dispatch({ type: 'TOGGLE_CART' });
  };
  const couponHandler = async (coupon) => {
    dispatch({ type: 'APPLY_COUPON', payload: coupon });
    setMixpanelUserProperty({ 'current_promotion': coupon?.promotion?.code || '' });
  };
  const removeCouponHandler = () => {
    dispatch({ type: 'REMOVE_COUPON' });
    setMixpanelUserProperty({ 'current_promotion': '' });
  };
  const rehydrateLocalState = (payload) => {
    dispatch({ type: 'REHYDRATE', payload });
  };
  const setPostcode = (payload) => {
    dispatch({ type: 'SET_POSTCODE', payload });
  }
  const isInCartHandler = (data) => {
    const currentShopId = state.items[0]?.shopUuid;
    return state.items?.some((item) => {
      if (item?.product?.upc === data?.product?.upc) {
        if (currentShopId) {
          return data?.shopUuid === currentShopId;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
  };
  const getItemHandler = (id) => {
    return state.items?.find((item) => item.product.upc === id);
  };
  const getCartItemsPrice = () => cartItemsTotalPrice(state.items);
  const getCartItemsTotalPrice = () =>
    cartItemsTotalPrice(state.items, state.coupon);

  const getDiscount = (value) => {
    const total = getCartItemsPrice();
    const coupon = state.coupon;

    if (coupon && coupon.promotion) {
      if (coupon.promotion.type == 'BASKET') {
        if (coupon.promotion.discountType === 'FLAT') {
          return coupon.promotion.value;
        } else if (coupon.promotion.discountType === 'PERCENTAGE') {
          return total*coupon.promotion.value;
        }
      } else if (coupon.promotion.type == 'DELIVERY') {
        return value;
      }
    }
    return 0;
  };
  
  const getItemsCount = state.items?.reduce(
    (acc, item) => acc + item.quantity,
    0
  );
  return {
    state,
    setPostcode,
    getItemsCount,
    rehydrateLocalState,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    toggleCartHandler,
    getCartItemsTotalPrice,
    getCartItemsPrice,
    couponHandler,
    removeCouponHandler,
    getDiscount,
  };
};

export const CartProvider = ({ children }) => {
  const {
    state,
    rehydrateLocalState,
    setPostcode,
    getItemsCount,
    addItemHandler,
    removeItemHandler,
    clearItemFromCartHandler,
    clearCartHandler,
    isInCartHandler,
    getItemHandler,
    toggleCartHandler,
    getCartItemsTotalPrice,
    couponHandler,
    removeCouponHandler,
    getCartItemsPrice,
    getDiscount,
  } = useCartActions();
  const { rehydrated, error } = useStorage(state, rehydrateLocalState);

  return (
    <CartContext.Provider
      value={{
        postcode: state.postcode,
        setPostcode: setPostcode,
        isOpen: state.isOpen,
        items: state.items,
        coupon: state.coupon,
        cartItemsCount: state.items?.length,
        itemsCount: getItemsCount,
        addItem: addItemHandler,
        removeItem: removeItemHandler,
        removeItemFromCart: clearItemFromCartHandler,
        clearCart: clearCartHandler,
        isInCart: isInCartHandler,
        getItem: getItemHandler,
        toggleCart: toggleCartHandler,
        calculatePrice: getCartItemsTotalPrice,
        calculateSubTotalPrice: getCartItemsPrice,
        applyCoupon: couponHandler,
        removeCoupon: removeCouponHandler,
        calculateDiscount: getDiscount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
