
export const Colors = `
  --devo-red: rgb(255, 89, 88);
  --devo-dark-red: rgb(157, 46, 60);
  --devo-orange: rgb(255, 142, 48);
  --devo-dark-orange: rgb(218, 70, 4);
  --devo-yellow: rgb(255, 202, 95);
  --devo-dark-yellow: rgb(255, 142, 48);

  --separator-color: #DEE2E6;
  --border-color: #818181;

  --background-color: rgb(255, 255, 255);
  --secondary-background-color: rgb(242, 242, 247);
  --tertiary-background-color: rgb(255, 255, 255);

  --primary-label-color: rgb(0, 0, 0);
  --secondary-label-color: rgba(60, 60, 67, 0.60);


  --white: #fff;
  --gray: rgb(142, 142, 147);
  --gray-2: rgb(174, 174, 178);
  --gray-3: rgb(199, 199, 204);
  --gray-4: rgb(209, 209, 214);
  --gray-5: rgb(229, 229, 234);
  --gray-6: rgb(242, 242, 247);
`;

export const Fonts = `
  --primary-font: Inter, Helvetica Neue, sans-serif;
  --secondary-font: Lato, Helvetica Neue, sans-serif;
`;