export const cartItemsTotalPrice = (items, coupon = null) => {
  let total = items.reduce((price, product) => {
    return price + (product.devoPrice * product.quantity);
  }, 0);

  var discount = 0;
  if (coupon && coupon.promotion) {
    if (coupon.promotion.type == 'BASKET') {
      if (coupon.promotion.discountType === 'FLAT') {
        discount = coupon.promotion.value;
      } else if (coupon.promotion.discountType === 'PERCENTAGE') {
        discount = total*coupon.promotion.value;
      }
    }
  }

  return total - discount;
};
// cartItems, cartItemToAdd
const addItemToCart = (state, action) => {
  const existingCartItemIndex = state.items.findIndex(
    (item) => item.product.upc === action.payload.product.upc
  );

  if (existingCartItemIndex > -1) {
    const newState = [...state.items];
    newState[existingCartItemIndex].quantity += action.payload.quantity;
    return newState;
  }
  return [...state.items, action.payload];
};

// cartItems, cartItemToRemove
const removeItemFromCart = (state, action) => {
  return state.items.reduce((acc, item) => {
    if (item.product.upc === action.payload.product.upc) {
      const newQuantity = item.quantity - action.payload.quantity;

      return newQuantity > 0
        ? [...acc, { ...item, quantity: newQuantity }]
        : [...acc];
    }
    return [...acc, item];
  }, []);
};

const clearItemFromCart = (state, action) => {
  return state.items.filter((item) => item.product.upc !== action.payload.product.upc);
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'REHYDRATE':
      return { ...state, ...action.payload };
    case 'TOGGLE_CART':
      return { ...state, isOpen: !state.isOpen };
    case 'ADD_ITEM':
      return { ...state, items: addItemToCart(state, action) };
    case 'REMOVE_ITEM':
      return { ...state, items: removeItemFromCart(state, action) };
    case 'CLEAR_ITEM_FROM_CART':
      return { ...state, items: clearItemFromCart(state, action) };
    case 'CLEAR_CART':
      return { ...state, items: [] };
    case 'APPLY_COUPON':
      return { ...state, coupon: action.payload };
    case 'REMOVE_COUPON':
      return { ...state, coupon: null };
    case 'SET_POSTCODE':
      return { ...state, postcode: action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
